.scopes-status {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.layer-hide-menu {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
}

.scopes-status-container {
  position: absolute;
  background-color: #ffffff;
  width: 40%;
  height: auto;
  border-radius: 10px;
  z-index: 300;
  padding: 0px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.show-scopes-status {
  visibility: visible;
}

.hide-scopes-status {
  visibility: hidden;
}

.scopes-status-container h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  padding: 20px 0px 10px 0px;
}

.scopes-status-container-scroll {
  max-height: 70vh;
  overflow: auto;
}

.scopes-status-button-container {
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.scopes-status-button-cancel {
  position: absolute;

  width: 110px;

  margin-right: 5%;
  padding: 7px 0px;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
  color: #ffffff;

  border: none;
  border-radius: 7px;

  font-size: 13px;
  overflow: hidden;
}

.scopes-status-button-cancel {
  background-color: #2f83c2;
  right: 0;
}

.scopes-status-button-cancel:hover {
  background-color: #12588d;
}

.scope-status-button-cancel:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.scopes-status-button-cancel:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

@media only screen and (max-width: 479px) {
  .scope-status-container {
    width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .scope-status-container {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {
  .scope-status-container {
    width: 65%;
  }
}

.progress-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.no-scopes-process {
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-size: 14px;
}

.progress-item {
  display: flex;
  flex-direction: column;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.username {
  font-size: 13px;
  font-weight: bold;
}

.filename {
  font-style: italic;
  font-size: 13px;
  padding-right: 2px;
  text-align: right;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.progress {
  height: 20px;
  background-color: #4699d7;
  padding-right: 5px;
  color: #000;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 5px 0 0 5px;
  font-weight: bold;
  font-size: 13px;
}

.update-status {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
  padding: 5px 10px;
  cursor: pointer;
  width: 100px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.update-status ion-icon {
  font-size: 20px;
}

.update-status:hover {
  background-color: #d0d0d0;
}

.update-status span {
  font-size: 16px;
  color: #000;
}