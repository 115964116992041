*{
  margin: 0;
  padding: 0;  
  font-family: 'Roboto', sans-serif;
}

.login-body {
  margin: 0;
  padding: 0;
  background-color: rgb(26,23,27);
}

.login-main {
  width: 100%;
  height: 100vh;
  background-image: url(../images/fondo.svg);
  background-size: cover;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Login contenedor (Caja principal - incluye el formulario y banner)*/
.login-container {
  width: 60%;
  height: 70%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: flex;
  align-items: center;
}

/* Formulario contenedor (Caja izquierda - contiene los inputs, botones, etc...)*/
.form-container {
  width: 50%;
  height: 100%;
  border-radius: 30px 0 0 30px;
  background-color: rgb(253, 249, 248 );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Titulo Login */
.form-titulo {
  color: black;
  font-size: 22px;
}

/* Logo */
.form-logo {
  width: 40%;
  object-fit: contain;
  margin-top: 15px;
}

/* Formulario login (caja que contiene todos los elementos de la etiqueta form)*/
.login-form {
  width: 70%;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Contenedor inputs (iconos,inputs, botones) */
.form-input {
  width: 100%;
  margin: 0 0 15px;
  padding: 7px 10px;
  font-size: 14px;  
  border: 2px solid;
  border-color: #e9e9e9;
  border-radius: 30px;

  display: flex;
  justify-content: space-between;
}

/* Caja que contiene el icono y el input */
.form-input .content-text {
  display: flex;
  gap: 0.5rem;
}

/* Iconos inputs*/
.form-input ion-icon {
  pointer-events: none;
  color: #6B6B6B;
  font-size: 24px;
}

/* Inputs usuario y contraseña */
.form-input input {  
  width: 210px;
  background: transparent;  
  border: none;
  outline: 0;
}

/* Botón mostrar contraseña */
.btn-eye{
  background: transparent;
  border: none;
  height: 24px;
}

.btn-eye:hover{
  background-color: #c9c9c9;
  border-radius: 50%;
}

/* Botón iniciar sesión */
.btn-login {
  width: 105%;  
  margin: 5px 0 10px;
  padding: 13px 15px;
  background-color: #2f83c2;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.btn-login:hover{
  background-color: #12588d;
}

/* Animación botón login */
.btn-login:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.btn-login:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

/* Divider */
.form-line {
  position: relative;
}

.form-line::before {
  content: '';
  width: 290px;
  height: 2px;
  background: #e9e9e9;
  display: inline-block;
}

/* Texto Copyright */
.copyright {
  text-align: center;
  color: #6B6B6B;
  font-size: 11px;
  margin-top: 10px;
}

.copyright a {
  text-decoration: none;
  color: #6B6B6B;
}

.copyright a:hover {
  text-decoration: underline;
}

/* Banner contenedor */
.banner-container {
  width: 50%;
  height: 100%;
  border-radius: 0 30px 30px 0;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-container-image{
  width: auto;
  height: 100%;
  border-radius: 0 30px 30px 0; 
}

/*Responsive Mobile*/

@media only screen and (max-width: 479px){
  /* Login contenedor (Caja principal - incluye el formulario y banner)*/
  .login-container {
    min-width: 350px;
    min-height: 200px;
    max-height: 510px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    align-items: center;
  }
  .form-container {
    width: 100%;
    border-radius: 30px;  
  }
  .banner-container {
    width: 0%;
  }
  .login-form {
    width: 80%;

  }
}

@media only screen and (min-width: 480px) and (max-width: 767px){
  /* Login contenedor (Caja principal - incluye el formulario y banner)*/
  .login-container {
    min-width: 400px;
    min-height: 420px;
    max-height: 480px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    align-items: center;
  }
  .form-container {
    width: 100%;
    border-radius: 30px;  
  }
  .banner-container {
    width: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px){
   /* Login contenedor (Caja principal - incluye el formulario y banner)*/
  .login-container {
    width: 770px;
    min-height: 430px;
    max-height: 440px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    align-items: center;
  }
  
}

@media only screen and (min-width: 1200px) {
  /* Login contenedor (Caja principal - incluye el formulario y banner)*/
  .login-container {
    width: 870px;
    height: 70%;
    min-height: 450px;
    max-height: 480px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    display: flex;
    align-items: center;
  }
}