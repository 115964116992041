* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  background-color: rgb(26, 23, 27);
}

.visor-body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: rgb(26, 23, 27);

}

/* Header */
.header-container {
  background-color: rgb(48, 46, 48);
  height: 65px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
}

/* Contenedor header izquierdo */
.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-menu {
  width: 36px;
  height: 36px;
  background: none;
  border: 0;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-menu:hover {
  background-color: rgb(131, 131, 131);
}

.header-left ion-icon {
  font-size: 26px;
  color: #ffffff;
  pointer-events: none;
}

.header-title {
  color: #ffffff;
  font-size: 23px;
}

.header-title:before {
  content: "RPD Rating Colombia";
}

.header-logo {
  width: 118px;
  height: 40px;
  object-fit: contain;

  border-radius: 10px;
  padding: 5px 10px;
}

/* contenedor header derecho*/
.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.btn-report,
.btn-exit {
  width: 36px;
  height: 36px;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-report {
  box-sizing: content-box;
  padding-right: 3px;
}

.header-right h3 {
  color: #ffffff;
  font-weight: 300;
  font-size: 15px;
}

.btn-exit {
  margin-right: 10px;
}

.btn-exit:hover,
.btn-report:hover {
  background-color: rgb(131, 131, 131);
}

.header-right ion-icon {
  font-size: 25px;
  color: #ffffff;
  margin-left: 3px;
  margin-top: 1px;
  pointer-events: none;
}

/* Main */
.visor-main {
  background-color: rgb(26, 23, 27);
  width: 100%;
  min-height: 600px;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.tab-menu {
  margin-top: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  display: none;
  background-color: transparent;
}

.show-tab-menu {
  display: block;
}

.tab-button {
  width: 50%;
  display: inline-flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: solid 1px #4798d6;
  color: #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  /* border: solid 1px #fcb614; */
}

.tab-button span {
  margin-top: 2px;
}

.tab-button ion-icon {
  font-size: 18px;
  box-sizing: border-box;
}

.tab-button:hover {
  background-color: rgb(71, 152, 214, 0.5);
  /* background-color: rgb(251, 182, 19, 0.5); */
}

.tab-button-selected {
  background-color: rgb(71, 152, 214, 0.75);
  pointer-events:none;
    /* background-color: rgb(251, 182, 19, 0.75); */
}

.tab-button-selected:hover {
  cursor: default;
  background-color: rgb(71, 152, 214, 0.75)
    /* background-color: rgb(251, 182, 19, 0.75); */
}

.main-container {
  position: relative;
  width: 90%;

  background-color: #ebebeb;

  overflow: hidden;
}

.show-main-container {
  margin-top: 10px;
}

.main-container:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

/* Power BI */
.report-style-class {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe {
  border: none;
}

/* Copyright */
.copyright-container {
  background-color: rgb(26, 23, 27);
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-text {
  color: #969696;
  margin-top: 15px;
  font-size: 14px;
}

.copyright-container a {
  text-decoration: none;
  color: #969696;
}

.copyright-container a:hover {
  text-decoration: underline;
}

/* Modal menu */
.modal-menu {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  visibility: hidden;
}

.show-modal-menu {
  visibility: visible;
}

.hide-modal-menu {
  visibility: hidden;
}

.layer-hide-menu {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
}

.menu-container {
  position: absolute;
  background-color: #ffffff;
  width: 290px;
  height: 100vh;
  transform: translateX(-290px);
  transition: transform 0.4s ease-out;
  z-index: 300;
  overflow-y: auto;
}

.menu-transition {
  transform: translateX(0px);
  transition: transform 0.4s ease-out;
}

.menu-header {
  background-color: rgb(48, 46, 48);
  height: 65px;
  color: #ffffff;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.menu-header ion-icon {
  font-size: 26px;
  color: #ffffff;
  pointer-events: none;
}

.menu-logo {
  width: 118px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
}

.menu-calendar {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  overflow: hidden;
}

.btn-month {
  width: 100%;
  background: transparent;
  padding: 6px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: #000;
  cursor: pointer;
}

.btn-month:hover {
  background-color: #ebebeb;
}

.btn-month ion-icon {
  color: #6B6B6B;
  font-size: 23px;
  pointer-events: none;
}

.btn-month span {
  display: block;
  font-weight: 300;
  font-size: 14px;
  user-select: none;
}

.btn-month.active {
  background-color: #ebebeb;
  cursor: default;
}

@keyframes ripples {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }

  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}

@media only screen and (max-width: 479px) {
  .header-right h3 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-title:before {
    content: "RPD";
    padding-left: 15px;
  }

  .header-logo {
    width: 108px;
    height: 35px;
  }

  .menu-logo {
    width: 108px;
    height: 35px;
    padding: 5px 10px;
  }

  .menu-header {
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .main-container {
    width: 80%;
  }

  .tab-menu {
    width: 80%;
  }
}