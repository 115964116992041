.layer-hide-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 200;
}

.certificate {
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
}

.certificate-container {
    position: absolute;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 300;
}

.show-certificate {
    visibility: visible;
}

.hide-certificate {
    visibility: hidden;
}

.certificate-pdf {
    margin-top: -2%;
    margin-left: -2.1%;
    width: 100%;
    height: 100%;
}

.certificate-button-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
}

.certificate-button-cancel,
.certificate-button-download {
    position: absolute;

    width: 19%;

    margin-right: 5%;
    padding: 8px 0px;

    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
    color: #ffffff;

    border: none;
    border-radius: 7px;

    font-size: 13px;
    overflow: hidden;
}

.certificate-button-cancel {
    background-color: #2f83c2;
    right: 20%;
}

.certificate-button-cancel:hover {
    background-color: #12588d;
}

.certificate-button-download {
    background-color: #64a454;
    right: 0;
    text-decoration: none;
    text-align: center;
}

.certificate-button-download:hover {
    background-color: #549145;
    cursor: default;
}

/* Animación botón login */
.certificate-button-cancel:after,
.certificate-button-download:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }

    20% {
        transform: scale(25, 25);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}

.certificate-button-cancel:focus:not(:active)::after,
.certificate-button-download:focus:not(:active)::after {
    animation: ripple 1s ease-out;
}


@media only screen and (max-width: 479px) {
    .certificate-container {
        width: 65%;
        height: 75%;
        overflow: hidden;
    }

    .certificate-body {
        width: 105%;
        height: 93%;
    }

    .certificate-pdf {
        margin-top: -3.5%;
        margin-left: -3.5%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .certificate-container {
        width: 60%;
        height: 75%;
        overflow: hidden;
    }

    .certificate-body {
        width: 105%;
        height: 94%;
    }

    .certificate-pdf {
        margin-top: -3.5%;
        margin-left: -3.5%;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .certificate-container {
        width: 45%;
        height: 85%;
        overflow: hidden;
    }

    .certificate-body {
        width: 104%;
        height: 96%;
    }

    .certificate-pdf {
        margin-top: -3.5%;
        margin-left: -3.5%;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (min-width: 1200px) {
    .certificate-container {
        width: 40%;
        height: 90%;
        overflow: hidden;
    }

    .certificate-body {
        width: 103%;
        height: 96%;
    }

    .certificate-pdf {
        margin-top: -2%;
        margin-left: -2.5%;
        width: 100%;
        height: 99%;
    }
}