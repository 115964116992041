.scope {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.layer-hide-menu {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
}

.scope-container {
  position: absolute;
  background-color: #ffffff;
  width: 40%;
  height: auto;
  border-radius: 10px;
  z-index: 300;
  padding: 0px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.show-scope {
  visibility: visible;
}

.hide-scope {
  visibility: hidden;
}

.scope-container h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  padding: 20px 0px 10px 0px;
}

.scope-container-scroll {
  max-height: 70vh;
  overflow: auto;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-left: 3.5%;
  margin-bottom: 10px;
}

input[type="email"] {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}

/* Drag and drop */
.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  font-size: 15px;
  margin: 0px 20px;
  cursor: pointer;
}

.drop-zone:hover{
  background-color: #e7e7e7;
  border: 2px dashed #bfbfbf;
}

.scope-file-input {
  display: none;
}

.drag-and-drop p{
  margin-top: 10px;
  text-align: center;
  font-style: italic;
}


.scope-button-container {
  width: 100%;
  height: 60px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.scope-button-download-2,
.scope-button-cancel,
.scope-button-download,
.scope-button-template {
  position: absolute;

  width: 19%;

  margin-right: 5%;
  padding: 8px 0px;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
  color: #ffffff;

  border: none;
  border-radius: 7px;

  font-size: 13px;
  overflow: hidden;
}

.scope-button-download-2 {
  background-color: #9333e7;
  right: 40%;
}

.scope-button-download-2:hover {
  background-color: #7e20cb;
}

.scope-button-cancel {
  background-color: #2f83c2;
  right: 40%;
}

.scope-button-cancel:hover {
  background-color: #12588d;
}

.scope-button-download {
  background-color: #64a454;
  right: 0;
}

.scope-button-download:hover {
  background-color: #549145;
}

.scope-button-download:disabled {
  background-color: #cccccc;
  color: #666666;
}

.scope-button-template {
  background-color: #9333e7;
  right: 20%;
}

.scope-button-template:hover {
  background-color: #7e20cb;
}

.scope-button-template:disabled {
  background-color: #cccccc;
  color: #666666;
}

/* Animación botón login */
.scope-button-download-2:after,
.scope-button-cancel:after,
.scope-button-download:after,
.scope-button-template:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.scope-button-download-2:focus:not(:active)::after,
.scope-button-cancel:focus:not(:active)::after,
.scope-button-download:focus:not(:active)::after,
.scope-button-template:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

@media only screen and (max-width: 479px) {
  .scope-container {
    width: 95%;
  }
}

@media only screen and (max-width: 767px) {
  .scope-container {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {
  .scope-container {
    width: 65%;
  }
}

input:where([type="checkbox"][id="toggle"]) {
  display: none;
}

label:where([role="switch"]) {
  display: inline-block;;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-left: 32px;
}

label:where([role="switch"])::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.15s;
}

input:where([type="checkbox"]):checked + label:where([role="switch"]) {
  background-color: #2196F3;
}

input:where([type="checkbox"]):checked + label:where([role="switch"])::before {
  transform: translateX(20px);
}

input:where([type="date"]) {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 90%;
  margin: auto;
}