.alert-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.alert {
    width: 340px;
    height: 45px;
    bottom: 35px;
    border-radius: 8px;

    opacity: 0.9;
    transition: 0.5s;

    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert-info {
    background-color: #2f83c2;
}

.alert-info:hover {
    background-color: #2a76af;
    opacity: 0.8;
    transition: 0.5s;
}

.alert-warning {
    background-color: #ff9900;
    width: 380px;
}

.alert-warning:hover {
    background-color: #f59300;
    opacity: 0.8;
    transition: 0.5s;
}

.alert-warning-pilot {
    background-color: #6C3B2A;
    width: 380px;
    height: 55px;
}

.alert-warning-pilot:hover {
    background-color: #613628;
    opacity: 0.8;
    transition: 0.5s;
}

.alert-danger {
    background-color: #b91717;
}

.alert-danger:hover {
    background-color: #c70e0ece;
    opacity: 0.8;
    transition: 0.5s;
}

.alert-danger-scope {
    background-color: #b91717;
    width: 380px;
    height: 55px;
}

.alert-danger-scope:hover {
    background-color: #c70e0ece;
    opacity: 0.8;
    transition: 0.5s;
}

.alert-success {
    background-color: #61d95a;
    width: 400px;
    height: 55px;
}

.alert-success:hover {
    background-color: #60d95aca;
    opacity: 0.8;
    transition: 0.5s;
}

.alert div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-icon-info {
    padding-left: 10px;
    padding-right: 5px;
    width: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.alert ion-icon {
    height: 45px;
    width: 25px;
    color: white;
}

.alert-text {
    text-align: center;
    color: white;
    font-size: 14px;
}

.btn-close {
    width: 35px;
    text-align: center;
    background-color: transparent;
    border: none;
    outline: 0;

    display: flex;
    justify-content: center;
    text-align: center;
}

.btn-close-info:hover {
    background-color: #3aa1f0;
    opacity: 0.8;
}

.btn-close-warning:hover {
    background-color: #ffa622;
    opacity: 0.8;
}

.btn-close-warning-pilot {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-close-warning-pilot:hover {
    background-color: #70412f;
    opacity: 0.8;
}

.btn-close-danger:hover {
    background-color: #ee1b0c;
    opacity: 0.8;
}

.btn-close-danger-scope {
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-close-danger-scope:hover {
    background-color: #ee1b0c;
    opacity: 0.8;
}

.btn-close-success:hover {
    background-color: #66e25f;
    opacity: 0.8;
}